import "./intro.scss";

import { FaInstagram, FaLinkedin, FaTwitter, FaFacebook } from "react-icons/fa";

export default function Intro() {
  return (
    <div className="intro" id="intro">
      <div className="introMessage">
        <h1>Human First Learning</h1>
        <div className="icons">
          <a href="https://www.linkedin.com/company/human-first-learning">
            <div className="icon">
              <FaLinkedin className="icon-li" />
            </div>
          </a>
          <a href="https://www.instagram.com/humanfirstlearning/">
            <div className="icon">
              <FaInstagram className="icon-ig" />
            </div>
          </a>
          <a href="https://twitter.com/">
            <div className="icon">
              <FaTwitter className="icon-tr" />
            </div>
          </a>
          <a href="https://www.facebook.com/">
            <div className="icon">
              <FaFacebook className="icon-fb" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
